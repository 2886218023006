import { React } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Stack,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardMedia,
  Typography,
} from '@mui/material';

import { fDate } from 'src/utils/format-time';

import logo from 'src/assets/logo/invoice -logo.png';
import { decimal } from 'src/server/http/axiosInstance';
import { table } from 'src/theme/overrides/components/table';

import TextMaxLine from 'src/components/text-max-line';

function SingleViewPDFDownload({ data, componentRef }) {
  console.log(data);

  const handleOrderStatus = () => {
    switch (data?.order_status) {
      case 'PENDING':
        return {
          color: 'warning',
          label: 'Pending',
        };
      case 'PROCESSING':
        return {
          color: 'primary',
          label: 'Processing',
        };
      case 'PICKUP_SCHEDULED':
        return {
          color: 'info',
          label: 'Pickup Scheduled',
        };
      case 'SHIPPED':
        return {
          color: 'info',
          label: 'Shipped',
        };
      case 'DELIVERED':
        return {
          color: 'info',
          label: 'Delivered',
        };
      case 'CANCELLED':
        return {
          color: 'error',
          label: 'Cancelled',
        };
      case 'CANCEL_INITIATED':
        return {
          color: 'error',
          label: 'Cancel Initiated',
        };
      case 'RETURNED':
        return {
          color: 'error',
          label: 'Returned',
        };
      case 'RETURN_INITIATED':
        return {
          color: 'error',
          label: 'Return Initiated',
        };
      default:
        return {
          color: 'default',
          label: '',
        };
    }
  };

  return (
    // <Container
    //   sx={{
    //     minWidth: '595px',
    //   }}
    // >
    <Stack ref={componentRef} px={10}>
      <Stack direction="row" alignItems="center" justifyContent="center" py={5}>
        <CardMedia
          component="img"
          src={logo}
          sx={{
            height: '100px',
            width: 'auto',
            objectFit: 'contain',
          }}
        />
      </Stack>
      <Divider color="#d3d3d3" />
      <Stack py={2} direction="row" alignItems="center" justifyContent="center">
        <Typography
          variant="h3"
          color="black"
          lineHeight={2}
          sx={{
            fontFamily: 'KoHo, sans-serif',
          }}
        >
          ORDER SUMMARY
        </Typography>
      </Stack>
      <Divider color="#E2E2E2" />
      <Stack direction="row" alignItems="flex-start" spacing={4} py={3}>
        <Box sx={{ flex: 1, p: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '25px' }}>
            Sold By :
          </Typography>
          <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
            Bulldex Stores
          </Typography>
          <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
            PO Box : 1869, PC 112, Muscat,
          </Typography>
          <Typography variant="body2" color="#000000">
            Sultanate of Oman
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            margin: '0 16px',
            border: '1px dashed #E2E2E2',
          }}
        />
        <Box sx={{ flex: 1, p: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '25px' }}>
            Billing Address :
          </Typography>
          {data?.is_billing_same_as_shipping === true ? (
            <>
              <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
                {data?.shipping_address?.name}
              </Typography>
              <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
                {[
                  data?.shipping_address?.district?.title?.english,
                  data?.shipping_address?.state?.title?.english,
                ]
                  .filter(Boolean)
                  .join(', ')}
              </Typography>
              <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
                {data?.shipping_address?.address}
              </Typography>
              <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
                {[data?.shipping_address?.address_line_1, data?.shipping_address?.address_line_2]
                  .filter(Boolean)
                  .join(', ')}
              </Typography>
              x
              <Typography variant="body2" color="#000000">
                {data?.shipping_address?.phone &&
                  `Phone: ${
                    data?.shipping_address?.country_code
                      ? `${data?.shipping_address?.country_code} `
                      : ''
                  }${data?.shipping_address?.phone}`}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
                {data?.billing_address?.name}
              </Typography>
              <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
                {data?.billing_address?.address},
              </Typography>
              <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
                {[
                  data?.billing_address?.country?.title?.english,
                  data?.billing_address?.state?.title?.english,
                  data?.billing_address?.district?.title?.english,
                ]
                  .filter(Boolean)
                  .join(', ')}
              </Typography>
              <Typography variant="body2" color="#000000">
                Phone: +{data?.billing_address?.country_code} {data?.billing_address?.phone}{' '}
              </Typography>
            </>
          )}
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            border: '1px dashed #E2E2E2',
            margin: '0 16px',
          }}
        />
        <Box sx={{ flex: 1, p: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '25px' }}>
            Shipping Address :
          </Typography>
          <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
            {data?.shipping_address?.name}
          </Typography>
          <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
            {[
              data?.shipping_address?.district?.title?.english,
              data?.shipping_address?.state?.title?.english,
            ]
              .filter(Boolean)
              .join(', ')}
          </Typography>
          <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
            {data?.shipping_address?.address}
          </Typography>

          <Typography variant="body2" color="#000000" sx={{ marginBottom: '4px' }}>
            {[data?.shipping_address?.address_line_1, data?.shipping_address?.address_line_2]
              .filter(Boolean)
              .join(', ')}
          </Typography>
          <Typography variant="body2" color="#000000">
            {data?.shipping_address?.phone &&
              `Phone: ${
                data?.shipping_address?.country_code
                  ? `${data?.shipping_address?.country_code} `
                  : ''
              }${data?.shipping_address?.phone}`}
          </Typography>
        </Box>
      </Stack>
      <Stack>
        <Divider color="#E2E2E2" />
      </Stack>
      <Stack>
        <Typography py={3} variant="h5">
          ORDER DETAILS :
        </Typography>
        <Stack direction="row" alignItems="start" justifyContent="space-between">
          <Box>
            <Stack>
              <Typography variant="body2" color="text.secondary">
                ORDER NO
              </Typography>
              <Typography variant="body2" color="text.primary">
                {data?.order_id}
              </Typography>
            </Stack>
            <Stack pt={3}>
              <Typography variant="body2" color="text.secondary">
                PAYMENT METHOD
              </Typography>
              <Typography variant="body2" color="text.primary">
                {data?.payment_type}
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Stack>
              <Typography variant="body2" color="text.secondary">
                ORDER DATE
              </Typography>
              <Typography variant="body2" color="text.primary">
                {fDate(data?.createdAt)}
              </Typography>
            </Stack>
            <Stack pt={3}>
              <Typography variant="body2" color="text.secondary">
                PAYMENT STATUS
              </Typography>
              <Typography variant="body2" color="text.primary">
                {handleOrderStatus()?.label}
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Stack>
              <Typography variant="body2" color="text.secondary">
                DELIVERY TYPE
              </Typography>
              <Typography variant="body2" color="text.primary">
                {data?.delivery_type === 'HOME_DELIVERY' ? 'Home Delivery' : 'Pickup'}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Stack pt={4} className="order-invoice-table">
        <Table size={table.dense ? 'small' : 'medium'}>
          <TableHead
            sx={{
              backgroundColor: 'transparent',
              borderTop: '1px solid #E2E2E2',
              borderBottom: '1px solid #E2E2E2',
            }}
          >
            <TableRow>
              <TableCell>S.NO.</TableCell>
              <TableCell>PRODUCT NAME</TableCell>
              <TableCell>QTY</TableCell>
              <TableCell align="center">
                <Typography variant="body2">UNIT PRICE</Typography>
              </TableCell>
              <TableCell>DISCOUNT</TableCell>
              <TableCell align="center">
                <Typography variant="body2">TOTAL PRICE</Typography>
              </TableCell>
              <TableCell textAlign="center" align="center">
                TAX AMOUNT
              </TableCell>
              <TableCell textAlign="center" align="center">
                TOTAL AMOUNT
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.order_items?.map((item, key) => (
              <TableRow
                key={key}
                sx={{
                  borderBottom: '1px dashed #E2E2E2',
                }}
              >
                <TableCell
                  sx={{
                    width: 15,
                  }}
                >
                  {key + 1}
                </TableCell>
                <TableCell>
                  <TextMaxLine line={2} sx={{ maxWidth: 350, minWidth: 200, textAlign: 'start' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                      {item?.product?.name?.english}
                    </Typography>
                  </TextMaxLine>
                </TableCell>
                <TableCell align="center">
                  {' '}
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                    {item?.quantity || '--'}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                    {item?.product_price ? `OMR ${item.product_price.toFixed(decimal)}` : '--'}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                    {item?.product_price_discount
                      ? `OMR ${item.product_price_discount.toFixed(decimal)}`
                      : '--'}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                    {item?.product_item_price
                      ? `OMR ${item.product_item_price?.toFixed(decimal)}`
                      : '--'}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                    {item?.tax_amount ? `OMR ${item.tax_amount?.toFixed(decimal)}` : '--'}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                    {item?.total_amount ? `OMR ${item.total_amount}` : '--'}
                  </Typography>
                </TableCell>
                <Divider
                  sx={{
                    width: '100%',
                    border: '2px',
                  }}
                  color="#E2E2E2"
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ width: '100%' }}
        pt={3}
      >
        <Grid item md={3} lg={3}>
          <Stack direction="row" justifyContent="space-between" spacing={10}>
            {/* Left Column - Labels */}
            <Stack spacing={1} sx={{ textAlign: 'right' }}>
              <Typography variant="body2" sx={{ py: 0.5 }}>
                TOTAL DISCOUNT
              </Typography>
              <Typography variant="body2" sx={{ py: 0.5 }}>
                TOTAL TAX AMOUNT
              </Typography>
              <Typography variant="body2" sx={{ py: 0.5, fontWeight: 'bold' }}>
                SUB TOTAL
              </Typography>
              <Typography variant="body2" sx={{ py: 0.5 }}>
                DELIVERY CHARGE
              </Typography>
              <Typography variant="h4" sx={{ py: 0.5, fontWeight: 'bold' }}>
                NET AMOUNT
              </Typography>
            </Stack>

            {/* Right Column - Values */}
            <Stack spacing={1} sx={{ textAlign: 'right' }}>
              <Typography variant="body2" sx={{ py: 0.5 }}>
                {data?.total_discount ? `OMR ${data.total_discount}` : '--'}
              </Typography>
              <Typography variant="body2" sx={{ py: 0.5 }}>
                {data?.tax_value ? `OMR ${data.tax_value}` : '--'}
              </Typography>
              <Typography variant="body2" sx={{ py: 0.5, fontWeight: 'bold' }}>
                {data?.total_price ? `OMR ${data.total_price.toFixed(decimal)}` : '--'}
              </Typography>
              <Typography variant="body2" sx={{ py: 0.5 }}>
                {data?.total_shipping_price ? `OMR ${data.total_shipping_price}` : '--'}
              </Typography>
              <Typography variant="h4" sx={{ py: 0.5, fontWeight: 'bold' }}>
                {data?.total_price ? `OMR ${data.total_price.toFixed(decimal)}` : '--'}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
    // </Container>
  );
}

export default SingleViewPDFDownload;

SingleViewPDFDownload.propTypes = {
  data: PropTypes.object,
  componentRef: PropTypes.any,
};
