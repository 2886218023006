import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';

import SyncIcon from '@mui/icons-material/Sync';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// @mui
import {
  Box,
  Grid,
  Card,
  Stack,
  Button,
  Divider,
  MenuItem,
  Container,
  Typography,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllOrder } from 'src/server/api/order';
import miniimg1 from 'src/assets/other/mini-img.svg';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllProduct } from 'src/server/api/product';
import miniimg3 from 'src/assets/other/mini-img-3.svg';
import miniimg2 from 'src/assets/other/mini-img-2.svg';
import overViewImg1 from 'src/assets/other/image-1.png';
import overViewImg2 from 'src/assets/other/image-2.png';
import overViewImg3 from 'src/assets/other/image-3.png';
import { decimal } from 'src/server/http/axiosInstance';
import { getDashboardData, getDashboardSyncData } from 'src/server/api/dashboard';

import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLoading from 'src/components/loading/DashboardLoading';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { TrafficGraph } from './traffic';
import { SubWidgets } from './sub-widget';
import { MainWidgets } from './main-widget';
import { EcommerceYearlySales } from './graph';
import { NewOrdersTable } from './new-orders-table';
import { OutOfStockProducts } from './out-of-stock-products';
import dashboardImg1 from '../../../assets/other/ic-glass-bag.svg';
import dashboardImg2 from '../../../assets/other/ic-glass-buy.svg';
import dashboardImg4 from '../../../assets/other/ic-ecommerce.svg';
import dashboardImg3 from '../../../assets/other/ic-glass-message.svg';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  // const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const popover = usePopover();
  // const [selectedYear, setSelectedYear] = useState('2024');
  const isMobile = useResponsive('sm', 'xs');
  const [tableData, setTableData] = useState([]);

  const useDashboardData = () => {
    const dashLoading = useSelector((state) => state.dashboard.loading);
    const order = useSelector((state) => state.order.order);
    const products = useSelector((state) => state.product.products);
    const dashboardCounts = useSelector((state) => state.dashboard?.dashboardData?.dashboard_data);
    const lastUpdatedTime = useSelector((state) => state.dashboard?.dashboardData);
    const syncLoading = useSelector((state) => state.dashboard?.syncLoading);

    // Memoize the result object
    return useMemo(
      () => ({
        dashLoading,
        order,
        products,
        dashboardCounts,
        lastUpdatedTime,
        syncLoading,
      }),
      [dashLoading, order, products, dashboardCounts, lastUpdatedTime, syncLoading]
    );
  };
  const { dashLoading, order, products, dashboardCounts, lastUpdatedTime, syncLoading } =
    useDashboardData();

  const [overViewFilterLabel, setOverViewLabel] = useState('This Month');
  const [overViewFilterValue, setOverViewFilterValue] = useState('MONTH');

  const [usersFilterLabel, setUsersFilterLabel] = useState('Lifetime');
  const [usersFilterValue, setUsersFilterValue] = useState('LIFETIME');

  const [graphFilterLabel, setGraphFilterLabel] = useState('Monthly');
  const [graphFilterValue, setGraphFilterValue] = useState('MONTH');
  // useEffect(() => {
  //   const credentials = {
  //     enqueueSnackbar,
  //   };
  //   dispatch(getDashboardData(credentials));
  // }, [dispatch, enqueueSnackbar]);

  // useEffect(() => {
  //   const credentialsYear = {
  //     enqueueSnackbar,
  //     year: selectedYear,
  //   };

  //   dispatch(getPartnerEarningChartData(credentialsYear));
  //   // dispatch(getPartnerSalesChartData(credentialsYear));
  // }, [dispatch, enqueueSnackbar, selectedYear]);

  const settings = useSettingsContext();

  const filterOptions = [
    { label: 'Today', value: 'TODAY' },
    { label: 'This Week', value: 'WEEK' },
    { label: 'This Month', value: 'MONTH' },
    { label: 'This Year', value: 'YEAR' },
  ];

  const graphFilter = [
    { label: 'Monthly', value: 'MONTH' },
    { label: 'Yearly', value: 'YEAR' },
  ];

  const handleChangeFilter = useCallback((newValue) => {
    setOverViewFilterValue(newValue?.value);
    setOverViewLabel(newValue?.label);
  }, []);

  useEffect(() => {
    setTableData(order?.orders);
  }, [order]);
  useEffect(() => {
    const filterDataObj = {
      filter_data: { is_deleted: false },
      sort_data: '',
    };
    const credentials = {
      page: 1,
      search: '',
      dispatch,
      limit: 5 || '',
      state: filterDataObj,
    };
    dispatch(getAllOrder(credentials));
  }, [dispatch]);

  const TABLE_HEAD_TITLE = [
    { id: 'order_id', label: 'Order ID' },
    { id: 'name', label: 'Name' },
    { id: 'product', label: 'Products' },
    { id: 'status', label: 'Status' },
    { id: 'amount', label: 'Amount' },
    { id: 'payment_type', label: 'Payment Type' },
  ];

  useEffect(() => {
    const filterSortObj = {
      filter_data: { is_deleted: false, stock: { $lte: 0 }, is_active: true },
    };
    const credentials = {
      page: 1,
      search: '',
      dispatch,
      limit: 5,
      state: filterSortObj,
    };
    dispatch(getAllProduct(credentials));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDashboardData({ enqueueSnackbar }));
  }, [dispatch]);

  const handleChangeOrderFilterValues = () => {
    const timePeriodMap = {
      TODAY: 'today',
      WEEK: 'this_week',
      MONTH: 'this_month',
      YEAR: 'this_year',
    };

    const timePeriod = timePeriodMap[overViewFilterValue];

    if (!timePeriod) {
      return null;
    }

    const livePrefix = `${timePeriod}_`;

    const getPreviousPrefix = (timePeriodKey) => {
      // Get the mapped time period value
      const mappedPeriod = timePeriodMap[timePeriodKey];
      // removing this_ from label and usage
      const formattedPeriod = mappedPeriod.startsWith('this_')
        ? mappedPeriod.replace('this_', '')
        : mappedPeriod;

      // separating today condition
      return timePeriodKey === 'TODAY' ? 'yesterday_' : `last_${formattedPeriod}_`;
    };

    // usage for fields
    const previousPrefix = getPreviousPrefix(overViewFilterValue);

    return {
      liveOrderValue:
        dashboardCounts?.overview_data?.total_orders_data?.[`${livePrefix}total_orders_count`],
      previousOrderValue:
        dashboardCounts?.overview_data?.total_orders_data?.[`${previousPrefix}total_orders_count`],
      liveProductSoldValue:
        dashboardCounts?.overview_data?.products_sold_data?.[`${livePrefix}products_sold_count`],
      previousProductSoldValue:
        dashboardCounts?.overview_data?.products_sold_data?.[
          `${previousPrefix}products_sold_count`
        ],
      liveSalesValue:
        dashboardCounts?.overview_data?.total_sales_data?.[`${livePrefix}total_sales_value`],
      previousSalesValue:
        dashboardCounts?.overview_data?.total_sales_data?.[`${previousPrefix}total_sales_value`],
    };
  };

  const handleUserCount = () => {
    const countProperty = {
      TODAY: 'today_users_count',
      WEEK: 'this_week_users_count',
      MONTH: 'this_month_users_count',
      YEAR: 'this_year_users_count',
      LIFETIME: 'lifetime_users_count',
    }[usersFilterValue];

    return {
      value: dashboardCounts?.users_data?.all_users_data?.[countProperty] || 0,
    };
  };

  const handleChangeUsersRolesCount = () => {
    const timePeriodMap = {
      TODAY: 'today',
      WEEK: 'this_week',
      MONTH: 'this_month',
      YEAR: 'this_year',
      LIFETIME: 'lifetime',
    };

    const timePeriod = timePeriodMap[usersFilterValue];

    if (!timePeriod) {
      return null;
    }

    return {
      registered:
        dashboardCounts?.users_data?.registered_users_data?.[
          `${timePeriod}_registered_users_count`
        ],
      guest: dashboardCounts?.users_data?.guest_users_data?.[`${timePeriod}_guest_users_count`],
    };
  };

  const handleUsersGraphPercentage = () => {
    const userRolesCount = handleChangeUsersRolesCount();
    const userCount = handleUserCount();

    if (userRolesCount && userCount && userCount.value) {
      const guestPercentage = (userRolesCount.guest / userCount.value) * 100;
      const registeredPercentage = (userRolesCount.registered / userCount.value) * 100;

      return { guestPercentage, registeredPercentage };
    }

    return { guestPercentage: 0, registeredPercentage: 0 };
  };

  const handleMainGraphValue = () => {
    if (graphFilterValue === 'MONTH') {
      //  monthly data
      const this_month_graph_value =
        dashboardCounts?.graph_data?.month_graph_data?.this_month_sales_data || [];
      const last_month_graph_value =
        dashboardCounts?.graph_data?.month_graph_data?.last_month_sales_data || [];

      const thisMonthValues = this_month_graph_value.map((i) => i?.total_price || 0);
      const lastMonthValues = last_month_graph_value.map((i) => i?.total_price || 0);

      return {
        thisValue: thisMonthValues,
        lastValue: lastMonthValues,
      };
    }
    //  yearly data
    const this_year_graph_value =
      dashboardCounts?.graph_data?.year_graph_data?.this_year_sales_data || [];
    const last_year_graph_value =
      dashboardCounts?.graph_data?.year_graph_data?.last_year_sales_data || [];

    const thisYearValues = this_year_graph_value.map((i) => i?.total_price || 0);
    const lastYearValues = last_year_graph_value.map((i) => i?.total_price || 0);

    return {
      thisValue: thisYearValues,
      lastValue: lastYearValues,
    };
  };

  const { thisValue, lastValue } = handleMainGraphValue();

  const handleSync = () => {
    dispatch(getDashboardSyncData({ enqueueSnackbar }));
  };

  const timeAgo = moment(lastUpdatedTime?.dashboard_data_last_updated_at)
    .fromNow()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return (
    <>
      <MetaHelmet title="Dashboard" />
      {syncLoading && (
        <Stack sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingScreen sx={{ width: '100%' }} maxWidth="100%" />
        </Stack>
      )}
      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        {!dashLoading ? (
          <>
            <Grid container>
              <Grid item xs={12} sm={4} md={4}>
                <MainWidgets
                  title="New Orders"
                  count={dashboardCounts?.primary_data?.new_orders_count || 0}
                  color="rgba(122, 65, 0, 1)"
                  bgColor="135deg, rgba(255, 245, 204, 0.5) 0%, rgba(255, 214, 102, 0.5) 100%"
                  icon={<img alt="icon" src={dashboardImg1} />}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} pl={!isMobile && 2} py={isMobile && 2}>
                <MainWidgets
                  title="Sales Today"
                  total={dashboardCounts?.primary_data?.today_total_sales_value || 0}
                  color="rgba(0, 75, 80, 1)"
                  bgColor="135deg, rgba(200, 250, 214, 0.5) 0%, rgba(91, 228, 155, 0.5) 100%"
                  price
                  icon={<img alt="icon" src={dashboardImg2} />}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} pl={!isMobile && 2} pb={isMobile && 2}>
                <MainWidgets
                  title="Total Products"
                  total={dashboardCounts?.primary_data?.total_products_count || 0}
                  color={
                    dashboardCounts?.primary_data?.total_stock_out_products_count > 0
                      ? 'rgba(122, 9, 22, 1)'
                      : 'rgba(99, 115, 129, 1)'
                  }
                  bgColor={
                    dashboardCounts?.primary_data?.total_stock_out_products_count > 0
                      ? '135deg, rgba(255, 172, 130, 0.5) 0%, rgba(255, 86, 48, 0.5) 100%'
                      : '90deg, rgba(238, 240, 242, 0.5) 0%, rgba(230, 232, 235, 0.5) 100%'
                  }
                  icon={
                    dashboardCounts?.primary_data?.total_stock_out_products_count > 0 ? (
                      <img alt="icon" src={dashboardImg3} />
                    ) : (
                      <img alt="icon" src={dashboardImg4} />
                    )
                  }
                  isSmall
                  subtitle="Total Products"
                  subtitleValue={dashboardCounts?.primary_data?.total_stock_out_products_count}
                />
              </Grid>
            </Grid>
            <Card
              sx={{
                p: 2,
                mt: 3,
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Stack>
                    <Typography variant="subtitle2">Overview</Typography>
                  </Stack>
                  <Stack ml={2}>
                    <Stack>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Button
                          onClick={popover.onOpen}
                          sx={{
                            border: '0.5px solid #D3D3D3',
                            width: 'auto',
                          }}
                        >
                          <Typography variant="caption" fontSize={14}>
                            {overViewFilterLabel}
                          </Typography>
                          &nbsp;&nbsp;
                          {popover?.open ? (
                            <KeyboardArrowUpIcon fontSize="14px" />
                          ) : (
                            <KeyboardArrowDownIcon fontSize="14px" />
                          )}
                        </Button>
                        <CustomPopover
                          anchorEl={popover.anchorEl}
                          open={popover.open}
                          onClose={popover.onClose}
                          arrow="top-right"
                          sx={{ width: 150 }}
                        >
                          {filterOptions?.map((item, key) => (
                            <MenuItem
                              key={key}
                              selected={item?.value === overViewFilterValue}
                              onClick={() => {
                                popover.onClose();
                                handleChangeFilter(item);
                              }}
                            >
                              {item?.label}
                              <Divider sx={{ mt: 0.2 }} />
                            </MenuItem>
                          ))}
                        </CustomPopover>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle2">Last Updated:&nbsp; {timeAgo}</Typography>
                  <Button
                    sx={{ ml: 3 }}
                    variant="soft"
                    color="success"
                    startIcon={<SyncIcon fontSize="small" />}
                    onClick={handleSync}
                  >
                    Sync Data
                  </Button>
                </Box>
              </Box>
              <Grid container pt={2} spacing={2}>
                <Grid item md={4} xs={12}>
                  <SubWidgets
                    title="Total Orders"
                    liveValue={handleChangeOrderFilterValues()?.liveOrderValue}
                    previousValue={handleChangeOrderFilterValues()?.previousOrderValue}
                    color="#4BA55A"
                    cardImage={overViewImg1}
                    bgColors="rgba(255, 171, 0, 0.08)"
                    miniImage={miniimg1}
                    filterValue={overViewFilterLabel}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <SubWidgets
                    title="Product Sold"
                    liveValue={handleChangeOrderFilterValues()?.liveProductSoldValue}
                    previousValue={handleChangeOrderFilterValues()?.previousProductSoldValue}
                    cardImage={overViewImg2}
                    color="#FAA702"
                    bgColors="rgba(142, 51, 255, 0.16)"
                    miniImage={miniimg2}
                    filterValue={overViewFilterLabel}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <SubWidgets
                    title="Total Sales"
                    liveValue={handleChangeOrderFilterValues()?.liveSalesValue?.toFixed(decimal)}
                    previousValue={handleChangeOrderFilterValues()?.previousSalesValue?.toFixed(
                      decimal
                    )}
                    color="#4BA55A"
                    cardImage={overViewImg3}
                    bgColors="rgba(0, 167, 111, 0.16)"
                    miniImage={miniimg3}
                    filterValue={overViewFilterLabel}
                  />
                </Grid>
              </Grid>

              <Grid container mt={2} spacing={2}>
                <Grid item xs={12} md={8} lg={8}>
                  <EcommerceYearlySales
                    graphFilter2={graphFilter}
                    graphFilterLabel={graphFilterLabel}
                    setGraphFilterLabel={setGraphFilterLabel}
                    graphFilterValue={graphFilterValue}
                    setGraphFilterValue={setGraphFilterValue}
                    lastYearGraphData={lastValue}
                    thisYearGraphData={thisValue}
                    dashboardCounts={dashboardCounts?.graph_data}
                    chart={{
                      categories:
                        graphFilterValue === 'MONTH'
                          ? Array.from({ length: 31 }, (_, i) => `${i + 1}`)
                          : [
                              'Jan',
                              'Feb',
                              'Mar',
                              'Apr',
                              'May',
                              'Jun',
                              'Jul',
                              'Aug',
                              'Sep',
                              'Oct',
                              'Nov',
                              'Dec',
                            ],
                      colors: ['#19AF4B', '#FBCB32'],
                    }}
                  />
                </Grid>
                <Grid item md={4} lg={4} xs={12}>
                  <TrafficGraph
                    title="Users"
                    total={handleUserCount()?.value}
                    filterValue={usersFilterValue}
                    setFilterValue={setUsersFilterValue}
                    filterLabel={usersFilterLabel}
                    setFilterLabel={setUsersFilterLabel}
                    chart={{
                      series: [
                        {
                          label: 'Registered',
                          value: handleUsersGraphPercentage()?.registeredPercentage?.toFixed(1),
                          counts: handleChangeUsersRolesCount()?.registered,
                        },
                        {
                          label: 'Guest',
                          value: handleUsersGraphPercentage()?.guestPercentage?.toFixed(1),
                          counts: handleChangeUsersRolesCount()?.guest,
                        },
                      ],
                    }}
                  />
                </Grid>
              </Grid>
            </Card>

            <Grid container spacing={2}>
              <Grid item md={8} lg={8}>
                <NewOrdersTable
                  title="Latest Orders"
                  tableData={tableData}
                  headLabel={TABLE_HEAD_TITLE}
                />
              </Grid>
              <Grid item md={4} lg={4} mt={2}>
                <OutOfStockProducts
                  title="Stock Out"
                  list={products?.products}
                  stockOutCount={products?.total}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          // <Stack alignItems="center" justifyContent="center" minHeight="70vh">
          //   <Typography>
          //     The Dashboard Panel is currently under development. You may access the Inventory
          //     sections for updates.{' '}
          //   </Typography>
          //   <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
          //     <Link to={paths.dashboard.inventory.root}>
          //       <Button variant="contained" size="small" color="success" sx={{ mt: 2 }}>
          //         Visit Inventory Panel
          //       </Button>
          //     </Link>
          //     <Link to={paths?.public?.release_note}>
          //       <Button variant="outlined" size="small" color="success" sx={{ mt: 2 }}>
          //         Release Note V2
          //       </Button>
          //     </Link>
          //   </Stack>
          //   {/* <DashboardContent setSelectedYear={setSelectedYear} selectedYear={selectedYear} /> */}
          // </Stack>
          <DashboardLoading />
        )}
      </Container>
    </>
  );
}

// background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
